$ico-download: "\e900";
$ico-faq: "\e901";
$ico-links: "\e902";
$ico-search: "\e903";
$ico-arrow-right: "\e904";

@mixin icon($icon,$size:$browser-context) {
  content: $icon;
  font-family: 'ibpt' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: em($size);
}

.ico {
  font-family: 'ibpt' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-download:before {
  content: $ico-download;
}
.ico-faq:before {
  content: $ico-faq;
}
.ico-links:before {
  content: $ico-links;
}
.ico-search:before {
  content: $ico-search;
}
.ico-arrow-right:before {
  content: $ico-arrow-right;
}
