.teaser {
    position: relative;
    background-color: $blue-xtra-light;
    padding-left: 15px;

    &:after {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        bottom: 0;
        width: 15px;
    }

    &:after {
        right: -15px;
        left: 100%;
    }

    .teaser-img {
        position: relative;
        z-index: 5;
        border-top-left-radius: rem(14);
        border-bottom-left-radius: rem(14);
        transform: translateY(1rem);

        &:after {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            bottom: 0;
            width: 15px;
            right: -15px;
            left: 100%;
        }

        img {
            border-radius: rem(14);
        }
    }

    .teaser-bg {
        position: relative;

        &:before, &:after {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            bottom: 0;
            width: 15px;
            z-index: 1;
        }

        &:before {
            left: -15px;
        }

        &:after {
            right: -15px;
        }
    }
    
    @include media-breakpoint-up(lg) {
        padding-left: 0;
        overflow: hidden;
        background-color: transparent;
        background-image: linear-gradient(to bottom, $blue-xtra-light 0px, $blue-xtra-light 50px, transparent 51px);

        &:before, &:after {
            content: none;
        }

        .teaser-bg, .teaser-bg-lg {
            position: relative;
    
            &:before, &:after {
                content: '';
                position: absolute;
                background-color: inherit;
                top: 0;
                bottom: 0;
                width: 500%;
            }
    
            &:before {
                left: 0;
                transform: translateX(calc(-100% + 1px));
            }
    
            &:after {
                right: 0;
                left: auto;
                transform: translateX(calc(100% - 1px));
            }
        }

        .teaser-img {
            transform: translateY(0);
        }
    }
}

.teaser-col {
    position: relative;
    padding-left: rem(25);
    height: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 5px;
        background-color: $secondary;
    }
}

.teaser-footer {
    a {
        color: white !important;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}