.table {
  caption {
    font-size: em(14);
    caption-side: top;
  }
  thead {
    background-color: $table-head-bg;
    th {
      border: $table-border-width solid $table-head-bg;
      vertical-align: middle;
    }
  }
  th, td {
    border: $table-border-width solid $table-border-color;
    padding: .7em;
    &:not(:first-child) {
      border-left: 1px solid transparent;
    }
    &:not(:last-child) {
      border-right: 1px solid transparent;
    }
  }
  a {
    text-decoration: underline;
  }
  &.table-bordered {
    // th, td {
    //   border: 0;
    //   border-bottom: $table-border-width solid $table-border-color;
    // }
  }
  &.highcharts {
    th, td {
      border-left: 0;
      border-right: 0;
    }
  }
}
.page-sidebar .table {
  thead {
    background-color: darken($table-head-bg, 10%);
  }
}
