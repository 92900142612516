.site-footer {
  background-color: $gray-900;
  color: $white;
  border-top: 4px solid $secondary;
  padding: em(30) 0;
  margin-top: 4rem;
  .navbar-brand {
    margin-bottom: em(28);
  }
  // .col {
  //   &:first-child {
  //     h2 {
  //       font-size: em(18);
  //     }
  //     @include media-breakpoint-up(lg) {
  //       padding-right: 6.5%;
  //     }
  //   }
  //   @include media-breakpoint-up(md) {
  //     & + .col {
  //       column-count: 2;
  //       column-gap: $grid-gutter-width;
  //     }
  //   }
  // }
  ul {
    margin-bottom: 2rem;
    break-before: avoid;
    break-inside: avoid;
    li {
      color: $gray-400;
      font-size: em(14);
      line-height: 2;
      a {
        color: $gray-400;
        &:focus {
          text-decoration: underline;
        }
      }
    }
    &.list-social {
      .list-inline-item:not(:last-child) {
        margin-right: 1.5rem;
      }
      a {
        font-size: em(24);
        color: $gray-400;
        &:hover {
          color: $white;
        }
        &:after {
          content: none !important;
        }
      }
    }
  }
}
i.fa-twitter {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBmaWxsPSIjY2VkNGRhIiBkPSJNMzg5LjIgNDhoNzAuNkwzMDUuNiAyMjQuMiA0ODcgNDY0SDM0NUwyMzMuNyAzMTguNiAxMDYuNSA0NjRIMzUuOEwyMDAuNyAyNzUuNSAyNi44IDQ4SDE3Mi40TDI3Mi45IDE4MC45IDM4OS4yIDQ4ek0zNjQuNCA0MjEuOGgzOS4xTDE1MS4xIDg4aC00MkwzNjQuNCA0MjEuOHoiLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: 0 2px;
  &:hover {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMzg5LjIgNDhoNzAuNkwzMDUuNiAyMjQuMiA0ODcgNDY0SDM0NUwyMzMuNyAzMTguNiAxMDYuNSA0NjRIMzUuOEwyMDAuNyAyNzUuNSAyNi44IDQ4SDE3Mi40TDI3Mi45IDE4MC45IDM4OS4yIDQ4ek0zNjQuNCA0MjEuOGgzOS4xTDE1MS4xIDg4aC00MkwzNjQuNCA0MjEuOHoiLz48L3N2Zz4=");
  }
  &:before {
    color: transparent;
  }
}