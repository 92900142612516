$thickness: .15em;
$spacing: .25em;
.navbar-toggler {
  @include nav-toggler($thickness,$spacing);
}
.search-toggler {
  position: relative;
  cursor: pointer;
  border: 0;
  transform: rotate(0deg);
  padding: 0;
  @include transition(.5s ease-in-out);
  .icon-bar {
    background-color: $white;
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 4px;
    left: 0;
    margin-top: 0 !important;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    opacity: 0;
    transform-origin: center center;
    &:first-child {
      top: 2px;
    }
    &:last-child {
      top: 18px;
    }
  }
  .fa-search {
    position: absolute;
    left: 0;
    top: -3px;
    opacity: 1;
    font-size: em(24);
    transition: .25s ease-in-out;
  }
  &[aria-expanded="true"] {
    .icon-bar {
      left: 0;
        opacity: 1;
      &:first-child {
        transform: rotate(45deg);
        top: 10px !important;
      }
      &:last-child {
        transform: rotate(-45deg);
        top: 10px !important;
      }
    }
    .fa-search {
      width: 0%;
      opacity: 0;
    }
  }
}