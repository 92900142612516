fieldset.form-group {
  margin: 0;
  border-top: 1px solid $gray-600;
  padding-top: 20px;
  padding-bottom: 20px;
  &:first-child {
    border: 0;
  }
  .legend {
    font-size: 1em;
    color: $primary;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: em(10);
  }
}
.form-group {
  position: relative;
  .control-label, // labels
  .form-control,  //input
  .custom-control,// radio & check
  .custom-select, // select
  .form-text {    // help text
    //font-size: em(14);
  }
  .control-label {
    margin-bottom: 0.3rem;
  }
  .form-control,  //input
  .custom-select {
    height: $input-height;
    color: $body-color;
  }
  .custom-select {
    &:disabled {
      color: $custom-select-disabled-color;
      background-image: $custom-select-disabled-indicator;
    }
  }
  .form-text {
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}