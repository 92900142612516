.navbar-light .navbar-brand {
  color: $gray-700;
}

.navbar-brand {
  display: flex;
  padding: 0;
  align-items: center;
  padding: 0;
  margin: 0;
  img {
    width: auto;
    height: 38px;
    width: 94px;
    vertical-align: text-bottom;
    @include media-breakpoint-up(lg) {
      height: 48px;
      width: 120px;
    }
  }
  small {
    font-size: em(12,20);
    line-height: 1.2;
    max-width: 190px;
    white-space: normal;
    margin-left: 2rem;
  }
}

.navbar {
  position: relative;
  padding: 0;
  @include media-breakpoint-up(lg) {
    flex-direction: column;
  }
  .block-header {
    width: 100%;
    border-bottom: 2px solid $secondary;
    @include media-breakpoint-up(lg) {
      border-bottom: 0;
    }
    > .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px $default-padding;
      height: 50px;
      @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
        height: auto;
        padding: 1rem $default-padding;
      }
    }
  }

  .dropdown-menu {
    background-color: $white;
    border: 0;
    border-radius: 0;
    padding: 0;
    border: 1px solid $border-color;
    .dropdown-item {
      padding: 10px $default-padding;
      border-bottom: 1px solid $border-color;
      white-space: normal;
    }
  }
  .nav-lang {
    font-size: em(14);
    margin: .25em 0;
    .dropdown-menu {
      min-width: 0;
    }
    a.dropdown-toggle {
      color: $primary;
    }
    a.dropdown-item {
      font-size: em(14);
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        background-color: $primary;
        color: $white;
      }
      &:last-child {
        border: 0;
      }
    }
  }

  .navbar-collapse {
    align-items: flex-start;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      margin: 10px 0 0;
      width: 100%;
      &#search-mobile {
        display: none !important;
      }
    }
    .tabs-container {
      width: 100%;
      margin-bottom: -4px;
      z-index: 1;
      .nav-tabs {
        width: 100%;
        border: 0;
        .nav-item {
          margin: 0;
          .nav-link {
            color: $body-color;
            background-color: $warm-gray-200;
            border: 0;
            border-bottom: 4px solid $secondary;
            text-transform: uppercase;
            font-size: em(15);
            line-height: em(16,15);
            padding: 12px 15px;
            transition: background-color .2s;
            &:not(.active):hover {
              background-color: $secondary;
            }
            &.active {
              color: $white;
              background-color: $primary;
              border-bottom: 4px solid $primary;
              &:focus {
                outline-color: $blue-light;
              }
            }
          }
        }
      }
    }
    .nav-item {
      // lvl3
      .list-title {
        display: block;
        color: $body-color;
        padding: 8px 10px;
      }
      .nav-link {
        color: $body-color;
        padding: 8px 10px;
        border-bottom: 1px solid $border-color;
        position: relative;
        &::after {
          position: absolute;
          top: 13px;
          right: 10px;
        }
        &[aria-expanded="true"]::after {
          top: 18px;
        }
        @include media-breakpoint-up(lg) {
          color: $white;
          &:after {
            position: relative;
            top: auto;
            right: auto;
          }
          &[aria-expanded="true"]::after {
            top: auto;
          }
        }
      }
    }
  }
  .main-menu {
    width: 100%;
    background-color: $white;
    @include media-breakpoint-down(md) {
      font-size: em(15);
      box-shadow: 0 5px 10px rgba($black,.3);
      #mobile-nav-header {
        > .container {
          padding-left: 10px;
          padding-right: 10px;
        }
        header.nav-target-group {
          font-size: em(16,15);
          font-weight: bold;
          text-transform: uppercase;
          padding: 8px 0;
        }
      }
      .mobile-nav-lang {
        display: flex;
        justify-content: flex-start;
        > li {
          width: 25%;
          border-right: 1px solid $border-color;
          &:nth-child(4) {
            border: 0;
          }
          > .nav-link {
            text-align: center;
            border-bottom: 2px solid transparent;
            &.active {
              border-color: $primary;
              color: $primary;
            }
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      background-color: $primary;
      border-top: 4px solid $secondary;
    }
    .sticky-logo {
      visibility: hidden;
      opacity: 0;
      transition: visibility .1s, opacity .2s, margin-left .2s;
      text-align: center;
      width: 50px;
      margin-left: -50px;
      img {
        width: auto;
        height: 34px;
        vertical-align: text-bottom;
      }
    }
    &.sticky-nav {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $zindex-fixed;
      box-shadow: $header-shadow;
      .sticky-logo {
        visibility: visible;
        opacity: 1;        
      }
    }
    .navbar-nav {
      @include media-breakpoint-up(lg) {
        align-items: center;
      }
      > .nav-item {
        @include media-breakpoint-up(lg) {
          position: static;
          align-self: stretch;
          &.menu-search {
            margin-left: auto;
            .nav-link {
              height: 100%;
              margin: 0;
              width: auto;
              border: 0;
              display: flex;
              align-items: center !important;
              .inner-search {
                display: block;
                width: 24px;
                height: 24px;
                margin: auto;
                position: relative;
              }
            }
          }
        }
        > .nav-link {
          font-size: em(17,15);
          @include media-breakpoint-up(lg) {
            font-size: em(15);
            line-height: em(16,15);
            padding: em(19,15) 1em em(12,15);
            border-bottom: 7px solid transparent;
            transition: border-color .2s;
            height: 100%;
            &:hover {
              border-color: rgba($secondary,.5);
              outline-offset: 2px;
            }
            &:focus,
            &:active,
            &[aria-expanded="true"] {
              background-color: darken($primary, 10);
            }
          }
          &.nav-target-group {
            font-size: 1em;
            text-transform: uppercase;
            background-color: $warm-gray-200;
            border-bottom: 0;
            margin-top: -1px;
          }
          &.active,
          &[aria-expanded="true"] {
            color: $primary;
            font-weight: bold;
            @include media-breakpoint-up(lg) {
              color: $white;
              font-weight: normal;
            }
          }
          &.active:not([aria-expanded="true"]) {
            @include media-breakpoint-up(lg) {
              border-color: $secondary;
            }
          }
        }
        
        .collapse,
        .collapsing {
          @include media-breakpoint-up(lg) {
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            background-color: darken($primary, 10);
            z-index: $zindex-dropdown;
          }
          > .container {
            padding: 0;
            .aside-xs {
              > .nav-link {
                background-color: $warm-gray-200;
                border-color: $white;
                &.active {
                  font-weight: bold;
                }
                &[aria-expanded="true"] {
                  color: $primary;
                }
              }
              ul {
                padding-left: 20px;
                li {
                  &:not(:last-child) {
                    border-bottom: 1px solid $border-color;
                  }
                  a {
                    text-decoration: underline;
                    border: 0;
                    &.active {
                      font-weight: bold;
                    }
                  }
                  ul {
                    padding-bottom: 10px;
                    li {
                      border: 0;
                      a {
                        padding: 5px 0;
                      }
                    }
                  }
                }
              }
            }
            > ul {
              @include media-breakpoint-up(lg) {
                padding: 15px 0 20px;
              }
              > li {
                @include media-breakpoint-up(lg) {
                  display: inline-block;
                  > a {
                    &.active {                    
                      @include underline(2px,.5em,1em);
                    }
                  }
                }
                > a {
                  background-color: $warm-gray-200;
                  border-color: $white;
                  @include media-breakpoint-up(lg) {
                    background-color: transparent;
                    border: 0;
                    padding: em(9,15) 1em;
                    margin-right: em(50,15);
                    &:hover,
                    &:focus {
                      @include underline(2px,.5em,1em);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // mobile
  .navbar-toggler {
    color: $primary;
  }
}