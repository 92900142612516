a {
  &:focus {
    outline: 2px dotted currentColor !important;
    outline-offset: 2px;
  }
}
.form-control, button {
  &:focus, &:focus-visible {
    outline: 3px dotted darken($blue-light,7) !important;;
  }
}
.orejime-Button:focus {
  box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
  outline-offset: 0 !important;
}
h1, .h1 {
  font-size: em(35);
  line-height: em(37,35);
  color: $primary;
  font-weight: 800;
  margin-bottom: 1.5rem;
}
h2, .h2 {
  font-size: em(25);
  color: $primary;
  margin-bottom: 1rem;
}
h3, .h3 {
  font-size: em(20);
  font-weight: bold;
  color: $body-color;
}
h4, .h4 {
  color: $gray-600;
  font-size: em(18);
  line-height: 1.5;
  font-weight: normal;
}
h5, .h5 {
  font-size: em(16);
  line-height: 1.3;
  font-weight: normal;
}

p:last-child {
  margin-bottom: 0;
}

.jumbotron {
  border-radius: 0;
  padding-left: $jumbotron-padding;
  padding-right: $jumbotron-padding;
  padding-bottom: $jumbotron-padding;
  .form-control {
    border-color: $gray-600;
  }
  .text-muted {
    color: $body-color;
  }
}

// Microsoft rendering 16px semibold FIX
// strong,
// dt {
//   font-size: $font-ie-fix;
// }

.fas.fa-arrow-right {
  position: relative;
  display: inline-block;
  margin-left: .5em;
  font-weight: 800;
  width: 14px;
  text-align: right;
  &:before {
    @include faw-icon($fa-var-angle-right);
    font-size: 1.2em;
  }
  &:after {
    content: "\2014";
    position: absolute;
    left: -1px;
    right: 2px;
    top: -20%;
    overflow: hidden;
    font-size: 1.2em;
  }
}

.font-weight-xtra-bold {
  font-weight: 800;
}

.bg-xtra-light {
  background-color: $blue-xtra-light;
}

.line-height-big {
  line-height: 1.5;
}

.line-height-heading {
  line-height: 1.2;
}

.text-underline {
  text-decoration: underline;
}

.lead {
  font-size: em(25);
  line-height: 1.3;
  font-weight: 800;

  @include media-breakpoint-up(lg) {
    font-size: em(30);
  }
}

.text-sm {
  font-size: rem(12);
}