#menu-search {
  .container {
    padding-top: em(40);
    padding-right: $default-padding;
    padding-bottom: em(50);
    padding-left: $default-padding;
  }
}

nav {
  .search-form {
    position: relative;
    min-width: rem(265);
    &:focus-within {
      border-color: $secondary;
    }
    label {
      color: $gray-500;
      font-size: em(12);
      text-transform: uppercase;
      font-weight: 600;
      display: block;
      width: 100%;
    }
    .form-control {
      line-height: 40px;
      height: 40px;
      border-radius: 25px !important;
      &::placeholder {
        color: $gray-600;
      }
    }
    .input-group-append {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border: 0;
      margin-left: 0;
      z-index: 5;
    }

    .btn {
      height: 30px;
      width: 30px;
      display: block;
      border-radius: 15px !important;
      margin-top: 5px;
      margin-right: 5px;

      > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #mobile-nav-header {
    .search-form {
      width: 100%;
    }
  }
}

.page-search {
  article {
    margin: em(20) 0;
  }
}