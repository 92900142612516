#back2top {
    color: white;
    background: rgba($primary, .9);
    width: em(60,12);
    height: em(60,12);
    border-radius: 50%;
    overflow:hidden;
    position:fixed;
    right:5rem;
    bottom:2.5rem;
    z-index:1000;
    font-size: em(12);
    line-height:1.1;
    text-align:center;
    text-decoration:none;
    transition: all 0.35s;
    display: block;
    @include media-breakpoint-down(md){
        display: none !important;
    }
    > * {
        position:absolute;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
        transition: all 0.35s;
    }
    span{
        display:block;
    }
    .fa {
        display:none;
        font-size:1.35rem;
        margin-top:em(-65);
        @include media-breakpoint-up(lg){
            display:block;
        }
    }
    &:hover{
        background:rgba($primary, 1);
        text-decoration:none;
        span{
            @include media-breakpoint-up(lg){
                margin-top:em(65);
            }
        }
        .fa{
            margin-top:0;
        }
    }
}
