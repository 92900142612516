.banner-home {
  background: url('../static/banner-circle-top-xs.png') no-repeat center top, url('../static/banner-home-xs.jpg') no-repeat center top,url('../static/banner-circle-bottom-xs.png') no-repeat center bottom;
  background-size: 101%,100%;
  position: relative;
  overflow: hidden;
  padding-top: 58%;
  padding-bottom: 22%;
  z-index: 0;
  @include media-breakpoint-up(md) {
    background: linear-gradient(to bottom, rgba(white,0) 58%, white), url('../static/banner-home.jpg') no-repeat center top;
    background-size: auto, cover;
    height: 575px;
    padding: 0;
  }
  .circle {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      top: -25%;
      right: -207px;
    }
    @include media-breakpoint-up(lg) {
      left: 47%;
      right: auto;
    }
    @include media-breakpoint-up(xl) {
      left: 50%;
      transform: translateX(55px);
    }
  }
  > .container {
    overflow: hidden;
    height: 100%;
    position: relative;
    z-index: 4;
    @include media-breakpoint-between(sm, md) {
      max-width: 100%;
    }
  }
  .banner-home-cta {
    @include media-breakpoint-up(md) {
      border-radius: 50%;
      background-color: $white;
      width: 390px;
      height: 390px;
      display: flex;
      justify-content: center;
      align-items: center;
      float: right;
      margin-top: 20px;
    }

    > div {
      color: $primary;
      text-align: center;
      font-size: em(22);
      line-height: 1.2;
      @include media-breakpoint-up(sm) {
        font-size: em(30);
      }
    }
    .btn {
      margin-top: em(26);
      border: 1px solid $primary;
      font-size: em(12,22);
      line-height: em(16,12);
      font-weight: bold;
      text-transform: uppercase;
      padding: 10px 1.5rem;
      border-radius: 19px;
      @include media-breakpoint-up(sm) {
        font-size: em(14,30);
        line-height: em(16,14);
        padding: $default-padding 2rem;
        border-radius: 24px;
      }
      &:after {
        @include faw-icon($fa-var-angle-right);
        margin-left: .5em;
      }
    }
  }
}
.menu-home {
  margin-top: -11%;
  @include media-breakpoint-up(md) {
    margin-top: -15%;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -150px;
  }
  @include media-breakpoint-up(xl) {

  }
  .col-12 {
    margin-bottom: 30px;
    > a {
      height: 100%;
      box-shadow: 0 0 20px 0 rgba($gray-900,.10);
      transition: transform .2s ease-in-out;
      transform: scale(1);
      display: flex;
      padding: 20px;
      @include media-breakpoint-up(md) {
        flex-direction: column;
        padding: 25px;
        min-height: 300px;
      }
      &:hover {
        transform: scale(1.03);
        text-decoration: none;
      }
      &:focus {
        box-shadow: 0 0 2px 4px rgba($secondary,.5), 0 0 20px 0 rgba($gray-900,.10);
      }
      .icon {
        display: block;
        margin-bottom: 30px;
        @include media-breakpoint-down(sm) {
          margin-right: 20px;
          flex: 0 0 55px;
          max-width: 55px;
        }
        img, svg {
          max-width: 55px;
          max-height: 55px;
        }
      }
      h2 {
        font-weight: 800;
      }
      p {
        font-size: em(18);
        &:last-of-type {
          display: inline;
        }
      }
    }
  }
  .white {
    .col-12 {
      > a {
        background-color: $white;
        color: $body-color;
        h2 {
          color: $primary;
        }
        svg > * {
          stroke: $blue-icon;
        }
      }
    }
  }
  .blue {
    .col-12 {
      > a {
        @include bluegradient(50%);
        color: $white;
        h2 {
          color: $white;
        }
        svg > * {
          stroke: $white;
        }
      }
    }
  }
}

.with-background-logo {
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 337px;
    height: 337px;
    background: url('../static/logo-bg.svg') no-repeat;
    position: absolute;
    left: -200px;
    top: -35px;
    z-index: -10;
  }
}