.page-body,
.site-footer {
  [href^="http://"],
  [href^="https://"],
  [target="_blank"],
  .external-link {
    &:after {
      @include faw-icon($fa-var-external-link);
      margin: 0 .35em;
      font-size: .8em;
    }
  }
}

.page-body,
.list-group-item {
  [href*=".pdf"] { 
    &:after {
      @include faw-icon($fa-var-file-pdf-o);
      font-size: .8em;
      margin: 0 em(2) 0 em(5);
    }
  }
  [href$=".doc"],
  [href$=".docx"] {
    :after {
      @include faw-icon($fa-var-file-word-o);
      margin: 0 .35em;
      font-size: .8em;
    }
  }
  [href$=".xls"],
  [href$=".xlsx"] {
    :after {
      @include faw-icon($fa-var-file-excel-o);
      margin: 0 .35em;
      font-size: .8em;
    }
  }
  [href$=".ppt"],
  [href$=".pptx"] {
    &:after {
      @include faw-icon($fa-var-file-powerpoint-o);
      margin: 0 .35em;
      font-size: .8em;
    }
  }
  [href*="youtube"] {
    &:after {
      @include faw-icon($fa-var-youtube-play);
      font-size: .8em;
      margin: 0 em(5) 0 em(2);
    }
  }
}

.page-body {
  a {
    @include link-deco();
  }
}
.list-group-item {
  a {
    @include link-deco(none);
  }
}