.bg-overflow-right, .bg-overflow-left {
    position: relative;
}

.bg-overflow-right {
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        right: -1000%;
        bottom: 0;
        background-color: inherit;
    }
}

.bg-overflow-left {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -1000%;
        right: 100%;
        bottom: 0;
        background-color: inherit;
    }
}