.site-header + .search-form {
  margin-top: em(60);
}
.btn-shadow {
  padding: .5rem .75rem;
  box-shadow: $box-shadow-down;
  &:focus {
    box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($primary, .5);
  }
}
.search-results {
  .jumbotron {
    background-color: $white;
    padding: 0;
    margin-bottom: 1.5rem;
  }
  .results-footer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    margin-top: .75rem;
    > p, a {
      //margin-top: 0.25rem;
      margin: 0;
      color: $body-color;
      &.form-text {
        font-weight: bold;
      }
    }
    .block-mobile-filters {
      margin: 2rem 0 1.5rem;
      @include media-breakpoint-up(lg) {
        display: none;
      }
      .dropdown-toggle {
        position: relative;
        padding: 15px 20px;
        font-size: em(20);
        font-weight: bold;
        color: $primary;
        &:after {
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }
  }
  .list-group-item {
    padding: em(20) 0;
    border-bottom: 0;
    margin: 0;
    border-color: $gray-300;
    &:first-child {
      border-top: 0;
    }
    &:last-child {
      border-bottom: 1px solid $gray-300;
    }
    > a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    .h3 {
      font-weight: normal;
      margin: 0;
      color: $primary;
    }
    .tags {
      font-size: em(14);
      color: $gray-600;
      margin-bottom: em(12,14);
      margin-top: em(5,14);
    }
    em {
      font-weight: bold;
      font-style: normal;
    }
    // .img-thumbnail {
    //   margin-right: em(20);
    //   @include media-breakpoint-down(sm) {
    //     width: 20%;
    //   }
    // }
    // .media-body {
    //   h2 {
    //     margin-bottom: em(3,22);
    //   }
    //   a {
    //     color: $body-color;
    //     text-decoration: none;
    //     &:hover {
    //       text-decoration: underline;
    //     }
    //   }
    //   .meta {
    //     color: red;
    //     margin: 0;
    //   }
    // }
  }
  .view-more {
    margin-top: 1.5rem;
  }
  @include media-breakpoint-down(sm) {
    .jumbotron {
      margin-bottom: 0;
    }
  }
}

#search-results {
  .collapse {
    [target="_blank"] {
      &:after {
        @include faw-icon($fa-var-external-link);
        margin: 0 .35em;
        font-size: .8em;
      }
    }
    
    a {
      text-decoration: underline;
    }
  }
}