#filters,
#mobile-filters {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  > aside {
    border-top: 4px solid $secondary;
    background-color: $blue-xtra-light;
    padding: 20px;
    margin-bottom: 20px;
    .aside-header,
    .aside-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 0;
    }
    .aside-header {
      padding-bottom: 0;
    }
    .aside-body {
      > .form-group {
        border-top: 1px solid $body-color;
        padding: 1.75rem 0;
        margin: 0;
      }
      > .sort-by {
        border: 0;
      }
    }
    h2 {
      font-size: 1.25rem;
      font-weight: bold;
    }
    h3 {
      font-size: 1rem;
      font-weight: bold;
      color: $primary;
    }
  }
}

.block-mobile-filters {
  border-top: 4px solid $secondary;
  background-color: $gray-200;
  #mobile-filters {
    .filters {
      border: 0;
      padding: 0 20px 1.75rem;
    }
    h2 {
      display: none;
    }
    .form-group:first-child {
      padding-top: 1rem;
    }
  }
}

.filters-active {
  .tags-group {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: .5rem 0;
    .tag-item {
      border: 1px solid $primary;
      background-color: $primary;
      color: $white;
      display: block;
      margin: 0 em(12) em(12) 0;
      font-size: em(14);
      line-height: em(30,15);
      padding: 1px .5em;
      //border-radius: .25em;
      a {
        margin-left: .25em;
        padding: 0 .25em;
        color: $white;
        display: inline-block;
        text-decoration: none !important;
        line-height: 1rem;
      }
    }
  }
}

.more_checkboxes {
  .more {
    display: inline-block;
  }
  .less {
    display: none;
  }

  &[aria-expanded="true"] {
    .more {
      display: none;
    }
    .less {
      display: inline-block;
    }
  }
}