ul.list-arrow {
  padding: 0;
  list-style-type: none;
  li {
    margin-bottom: em(6);
    padding-left: $fa-li-width;
    &:before {
      width: $fa-li-width;
      margin-left: -$fa-li-width;
      @include faw-icon($fa-var-angle-right);
    }
  }
  ol {
    li {
      padding-left: 0;
      &:before {
        content: none;
      }
    }
  }
}
// ul:not(.nav):not(.navbar-nav):not(.list-inline):not(.list-group):not(.tags-group):not(.dropdown-menu):not(.timeline):not([role="listbox"]):not(.carousel-indicators) {
//   padding: 0;
//   list-style-type: none;
//   li {
//     margin-bottom: em(6);
//     padding-left: $fa-li-width;
//     &:before {
//       width: $fa-li-width;
//       margin-left: -$fa-li-width;
//       @include faw-icon($fa-var-angle-right);
//     }
//   }
//   ol {
//     li {
//       padding-left: 0;
//       &:before {
//         content: none;
//       }
//     }
//   }
// }
// ul {
//   li {
//     p {
//       display: inline-block;
//       vertical-align: top;
//     }
//   }
// }
// ul.list-links {
//   li {
//     color: $primary;
//   }
// }
// .media-body {
//   ul.list-authors {
//     display: inline;
//     li {
//       display: inline-block;
//       &:not(:last-child):after {
//         content: ' - ';
//       }
//     }
//   }
// }