.highcharts-chart-tab {
    padding-top: 1rem;
}
.table.highcharts {
  margin-top: 1rem;
}

.highcharts-credits {
  color: #767676 !important;
  fill: #767676 !important;
}