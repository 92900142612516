.site-header {
  height: 52px;
  @include media-breakpoint-up(lg) {
    height: auto;
    position: relative;
    padding: 0;
  }
  > .navbar {
    box-shadow: $header-shadow;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 100%;
    z-index: $zindex-fixed;
    padding: 0;
    background-color: $white;
    overflow-y: auto;
    @include media-breakpoint-up(lg) {
      position: relative;
      max-height: none;
      overflow-y: visible;
    }
  }
}