.pagination {
  .page-item {
    .page-link {
      border: 0;
      border-radius: 0 !important;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        background-color: transparent;
      }
    }
    &.active {
      .page-link {
        background-color: transparent;
        color: $body-color;
        text-decoration: none;
      }
    }
  }
}