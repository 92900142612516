.breadcrumb {
  border-bottom: 1px solid $gray-300;
  font-size: em(14);
  line-height: em(16,14);
  background-color: $white;
  .breadcrumb-item {
    &.active {
      color: $body-color;
    }
    & + .breadcrumb-item::before {
      @include faw-icon($fa-var-angle-right);
    }
  }
}
.admin-menu-wrapper {
  margin-left: .5rem;
  line-height: 1;
}
.page {
  > .page-header {
    margin-top: em(40);
    @include media-breakpoint-up(lg) {
      margin-top: em(60);
    }
    h1 {
      line-height: 1.2;
      + .file-meta {
        margin-top: -.75rem;
        margin-bottom: 2rem;
        color: $gray-600;
      }
    }
  }

  .page-body {
    h2:not(:first-of-type) {
      margin-top: em(50,28);
    }
    h3 {
      margin-top: 2rem;
    }
    h4 {
      margin-top: 1.5rem;
    }
    img:not(.img-fluid) {
      max-width: 100%;
      height: auto;
    }
    iframe:not(.embed-responsive-item) {
      max-width: 100%;
      margin-bottom: 2rem;
    }
    .embed-responsive {
      margin-bottom: 2rem;
    }
    hr {
      margin-top: 2rem;
      margin-bottom: 2rem;
      border-color: $gray-300;
    }
    figure, img {
      figcaption {
        background-color: $gray-200;
        padding: .5em 1em;
        font-size: 0.875em;
      }
      &[style*="left"] {
        margin-right: $grid-gutter-width;
        margin-top: 5px;
        margin-bottom: 0;
      }
      &[style*="right"] {
        margin-left: $grid-gutter-width;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
    ul, ol {
      padding-inline-start: 20px;
      li {
        line-height: 1.625em;
      }
    }
    ul {
      list-style-type: disc;
    }
    img {
      max-width: 100%;
    }
  }

}

.download-box, .faq-overview, .publications-overview {
  position: relative;
  padding-left: 2rem;
  &:before {
    @include icon($ico-download,24);
    color: $secondary;
    vertical-align: middle;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.faq-overview:before {
  color: $secondary;
  @include icon($ico-faq,24);
}

.page-404 a {
  text-decoration: underline;
}
// .page-header-img,
// .page-header-profile,
// .page-teaser,
// .jumbotron {
//   margin-left: -$default-padding;
//   margin-right: -$default-padding;
//   @include media-breakpoint-up(lg) {
//     margin-left: 0;
//     margin-right: 0;
//   }
// }
// .page-header-img {
//   position: relative;
//   display: block;
//   flex-direction: column;
//   .banner {
//     display: block;
//     order: 1;
//     @include media-breakpoint-up(md) {
//       padding: 0 42px 45px;
//     }
//   }
//   h1 {
//     min-height: 70px;
//     margin: 0;
//     color: $white;
//     background-color: $primary;
//     text-align: left;
//     z-index: 10;
//     padding: $default-padding;
//     width: 100%;
//     //order: 2;
//     @include media-breakpoint-up(md) {
//       position: absolute;
//       bottom: 0;
//       left: 0;
//       width: auto;
//       max-width: 50%;
//       padding: 0.75em;
//     }
//   }
//   @include media-breakpoint-up(md) {
//     + .row {
//       .page-teaser {
//         margin-top: -45px;
//         padding: 80px 30px 30px;
//       }
//     }
//   }
// }
// .page-teaser {
//   padding: 30px;
//   a {
//     color: $body-color;
//     text-decoration: underline;
//     &:hover {
//       text-decoration: none;
//     }
//   }
// }
// .page-toc {
//   padding-top: 2.5rem;
//   padding-bottom: 1rem;
//   margin-bottom: 0;
//   h2 {
//     margin-bottom: 1rem;
//   }
//   #toc {
//     li {
//       color: $primary;
//       &:before {
//         content: ' • ';
//       }
//       ul {
//         margin-top: em(6);
//         margin-bottom: 0;
//       }
//       a {
//         text-decoration: underline;
//         &:hover {
//           text-decoration: none;
//         }
//         &[href^="#h2"] {
//           &:after {
//             content: " (h2)";
//           }
//         }
//         &[href^="#h3"] {
//           &:after {
//             content: " (h3)";
//           }
//         }
//         &[href^="#h4"] {
//           &:after {
//             content: " (h4)";
//           }
//         }
//         &[href^="#h5"] {
//           &:after {
//             content: " (h5)";
//           }
//         }
//       }
//     }
//   }
// }
// .page-body {
//   padding: 1.5rem 0 0;
//   @include media-breakpoint-up(sm) {
//     padding: 2rem 0 0;
//   }
//   @include media-breakpoint-up(lg) {
//     padding: 2.5rem 0;
//   }
//   
//   a {
//     color: $body-color;
//     @include link-deco();
//   }
//   img:not(.img-fluid) {
//     max-width: 100%;
//     height: auto;
//   }
//   iframe:not(.embed-responsive-item) {
//     max-width: 100%;
//     margin-bottom: 2rem;
//   }
//   .embed-responsive {
//     margin-bottom: 2rem;
//   }
//   hr {
//     margin-top: 2rem;
//     margin-bottom: 2rem;
//     border-color: $gray-300;
//   }
// }
// figure.image {
//   display: inline-grid;
// }
// .page-links {
//   border-top: 1px solid $gray-300;
//   margin-top: 2.5rem;
//   padding-top: 2.5rem;
//   li {
//     color: $primary;
//     a {
//       color: $primary;
//       @include link-deco(none);
//     }
//   }
// }
// .page-simple {
//   h1 {
//     color: $primary;
//   }
//   h2 {
//     color: $body-color;
//   }
// }
