.alert-warning {
    color: black;
    background-color: $warning;
    border-color: $warning;
}

.alert-danger {
    color: black;
    background-color: $danger;
    border-color: $danger;
}

.alert-dismissible .close {
    text-shadow: none;
    opacity: 0.75;

    &:hover {
        opacity: 1 !important;
    }
}

.alert {
    padding-left: 0;
    padding-right: 0 !important;
}

.alert-content {
    padding-right: 4rem;

    p:last-child {
        margin-bottom: 0;
    }
}