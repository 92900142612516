.block-collapse,
.target-collapse {
  padding: em(10) 0;
  border-bottom: 1px solid $border-color;
  margin-bottom: 1rem;
  & + .block-collapse,
  & + .target-collapse {
    margin-top: -1rem;
  }
  > [data-toggle="collapse"] {
    position: relative;
    display: block;
    color: $body-color;
    text-decoration: none;
    padding-right: 1.2rem;
    &:hover {
      text-decoration: underline;
    }
    &:before,
    &:after{
      content: "";
      position: absolute;
      background-color: $primary;
      transition: transform 0.25s ease-out, opacity 0.25s ease-out;
    }
    &:before{
      top: 12px;
      right: .325em;
      width: .1em;
      height: .75em;
      margin-top: -.375em;
    }
    /* horizontal line */
    &:after{
      top: 12px;
      right: 0;
      width: .75em;
      height: .1em;
      margin-top: -.05em;
    }
    &[aria-expanded="false"] {
      &:before{ transform: rotate(0); opacity: 1;}
      &:after{ transform: rotate(0); }
    }
    &[aria-expanded="true"] {
      &:before{ transform: rotate(90deg); opacity: 0; }
      &:after{ transform: rotate(180deg); }
    }
  }
  > div {
    p {
      margin-top: 1rem;
      margin-bottom: 0;
      & + p {
        margin-top: .5rem;
      }
    }
  }
}