.page-sidebar {
  > * {
    margin-bottom: em(50);
  }
  h2 {
    font-size: em(18);
    font-weight: bold;
    &:before {
      display: block;
      height: 46px;
      margin-right: 1rem;
      color: $secondary;
    }
  }
  a {
    text-decoration: underline;
    color: $body-color;
    &:hover {
      text-decoration: none;
    }
  }
  .faqs {
    h2:before {
      @include icon($ico-faq,40);
    }
  }
  .publications {
    h2:before {
      @include icon($ico-download,40);
    }
  }
  .links {
    h2:before {
      @include icon($ico-links,40);
    }
  }
  .file-meta {
    border-top: 4px solid $secondary;
    background-color: $gray-200;
    padding: 20px;
    dd {
      margin-bottom: 1.5rem;
    }
  }
  
  > .menu {
    @include media-breakpoint-down(md) {
      display: none;
    }
    ul {
      border-bottom: 1px solid $gray-300;
      li {
        border-top: 1px solid $gray-300;
        border-left: 4px solid transparent;
        &.active {
          border-left: 4px solid $secondary;
          // font-weight: bold;
          a, span {
            padding-left: 10px;
          }
        }
        a, span {
          display: block;
          padding: 8px 10px 8px 0;
          color: $body-color;
        }
        a:not([data-toggle="collapse"]) {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        a[data-toggle="collapse"] {
          text-decoration: none;
          position: relative;
          @include caret;
          transition: all .2s;
          &[aria-expanded="true"] {
            position: relative;
            &::after {
              transform: rotate(-45deg) scale(1,-1);
              vertical-align: 0;
              top: 1rem;
            }
          }

          &:after {
            position: absolute;
            right: 0;
            top: 0.8em;
          }
        }
        .submenu {
          li {
            a, span {
              display: inline-block;
              color: $gray-600;
              padding: 0 0 0 10px;
            }
            &:before {
              text-align: right;
            }

            &.active {
              a, span {
                color: $gray-600;
              }
            }
          }
        }
        ul {
          border-bottom: 0;
          margin-bottom: 1.250rem;
          li {
            border-top: 0;
          }
        }
      }
    }
  }
}