$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@mixin faw-icon($fa-icon,$size:$browser-context) {
  content: $fa-icon;
  @include fa-icon;
  font-size: em($size);
}

@mixin fontface($name, $file, $weight: normal, $style: normal){
  @font-face {
    font-family: '#{$name}';
    src: url('#{$file}.woff2') format('woff2'),
         url('#{$file}.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}
@mixin nav-toggler($thickness,$spacing,$color: $primary) {
  $middle-position: $thickness + $spacing;
  width: 24px;
  height: 20px;
  border-radius: 0;
  padding: 0;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  border: 0;
  margin-left: 1em;
  @include media-breakpoint-up(sm) {
    margin-left: 2em;
  }
  .navbar-toggler-item {
    display: block;
    position: absolute;
    height: $thickness;
    width: 100%;
    background: $color;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    &:nth-child(1),
    &:nth-child(2) {
      top: $middle-position;
    }
    &:nth-child(3) {
      top: 0;
    }
    &:nth-child(4) {
      top: $middle-position*2;
    }
  }
  &[aria-expanded="true"] {
    .navbar-toggler-item {
      &:nth-child(1) {
        transform: rotate(45deg);
        height: $thickness/1.5;
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
        height: $thickness/1.5;
      }
      &:nth-child(3),
      &:nth-child(4) {
        top: $middle-position;
        width: 0%;
        left: 50%;
      }
    }
  }
}

@mixin underline($thick: 1px,$bottom: 0,$px: 0,$color: currentColor) {
  &:after {
    content: '';
    position: absolute;
    left: $px;
    right: $px;
    bottom: $bottom;
    border-bottom: $thick solid $color;
  }
}

@mixin bluegradient($start:0%,$end:100%) {
  background: $primary;
  background: linear-gradient(45deg, $primary $start, $secondary $end);
}


@mixin link-deco($decoration: underline) {
  @if $decoration == none {
    text-decoration: $decoration;
    &:hover {
      text-decoration: underline;
    }
    // IE fix for underlined pseudo classes
    &:before,
    &:after {
      text-decoration: $decoration;
    }
    &:before,
    &:after {
      text-decoration: underline;
    }
  } @else {
    text-decoration: $decoration;
    &:hover {
      text-decoration: none;
    }
    // IE fix for underlined pseudo classes
    &:before,
    &:after {
      text-decoration: $decoration;
    }
    &:before,
    &:after {
      text-decoration: none;
    }
  }
}

@mixin link-arrow($color: $body-color, $deco: underline) {
  color: $color;
  @include link-deco($deco);
  &:after {
    @include faw-icon($fa-var-angle-right);
    margin-left: .5em;
  }
}