#euCookie {
  @include bluegradient(50%);
  color: white;
  padding: em(20) 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 600;
  display: none;
  // min-height: 100px;
  // @include media-breakpoint-up(lg) {
  //   min-height: 170px;
  // }
  @media (min-width: 992px) {
    p {
      margin: 0;
    }
  }
  ul.list-inline {
    margin: -0.33333rem -0.66667rem -0.33333rem -0.33333rem;
    @media (min-width: 992px) {
      margin-left: 1rem;
    }
    .list-inline-item {
      margin: 0.33333rem 0.66667rem 0.33333rem 0.33333rem;
    }
  }
  .btn {
    border-color: $white;
    background-color: transparent;
    color: $white;
    &:hover,&:focus {
      color: $primary;
      background-color: $white;
    }
    .fa {
      font-size: 1.5em;
      vertical-align: top;
      margin-right: .25em;
    }
  }
}
